import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import { FooterAlternate1 } from "../../../components/footer"
import SEO from "../../../components/seo"

const IndexPage = ({location}) => (
    <Layout location={location}>
      <SEO title="I want to make a career change...." />

      <article className="article">
        <header className="header header--primary article__header">
            <div className="header__image">
                <div className="header__bgimage" style={{backgroundImage:"url(/media/svg/landing-256x183_man-pensive.svg)"}}></div>
            </div>
            <div className="header__content">
                <div className="container">
                    <div className="row">
                        <div className="header__inner col-sm-9 col-md-7 col-lg-6 col-lg-offset-1">
                            <Link to="/knowledge-centre/challenges/" className="header__back back-link">
                                <span className="sr-only">Back to all articles</span>
                                <i className="icon"></i>
                            </Link>
                            <div className="header__title">
                                <h3>Challenges</h3>
                                <h1>I want to make a career change....</h1>
                            </div>
                            <h3 className="header__caption">Your career now depends on how well you understand the new realities of sales, and how well you leverage them to shape your future.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="container" id="article-content">
            <div className="row">
                <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-7">
                    <div className="rich-text">
                        <p></p>
                        <p></p>
                        <p></p>
                        <p>How will you respond? Sit back and wait to see how it all unfolds? Or take a proactive stance in order to protect your career and your future livelihood?</p>
                        <p>With changing technology, changing buyer values, and increasing pressures within vendor businesses, the traditional role of sales is now morphing into something very different. The digital sales revolution will continue to adapt to meet the requirements of today’s virtually connected buyers, and sales people must rapidly up-skill in order to become:</p>
                        <p></p>
                        <ul>
                            <li>Digitally Driven
                                <br/>
                            </li>
                            <li>Socially Connected
                                <br/>
                            </li>
                            <li>Mobile (always-on)
                                <br/>
                            </li>
                            <li>Highly specialized.
                                <br/>
                            </li>
                        </ul>
                        <p></p>
                        <p></p>
                        <p>Join SalesTribe today (it's free) - we can help re-purpose and re-connect you into exciting new career opportunities. </p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                    </div>
                </div>
                <aside className="article__sidebar col-sm-12 col-md-4 col-lg-offset-1 col-lg-3">
                    <div className="article__sidebar-wrap" id="article-sidebar">
                        <h3>Are you ready to make a change?</h3>
                        <div className="article__sidebar-btns">
                                <a target="_self" href="https://community.salestribe.com/" className="btn btn-default">Join for Free!</a>
                        </div>
                        <nav className="social-nav">
                            <h4>Share</h4>
                            <ul>
                                <li className="linkedin">
                                    <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A//salestribe.com/knowledge-centre/challenges/i-want-make-career-change/&amp;title=I%20want%20to%20make%20a%20career%20change....&amp;summary=I%20want%20to%20make%20a%20career%20change....&amp;source=https%3A//salestribe.com/knowledge-centre/challenges/i-want-make-career-change/" target="_blank" rel="noopener noreferrer">
                                        <span className="sr-only">LinkedIn</span>
                                        <span className="icon icon-linkedin"></span>

                                    </a>
                                </li>
                                <li className="twitter">
                                    <a href="https://twitter.com/intent/tweet/?text=I%20want%20to%20make%20a%20career%20change....&amp;url=https%3A//salestribe.com/knowledge-centre/challenges/i-want-make-career-change/" target="_blank" rel="noopener noreferrer">
                                        <span className="sr-only">Twitter</span>
                                        <span className="icon icon-twitter"></span>

                                    </a>
                                </li>
                                <li className="googleplus">
                                    <a href="https://plus.google.com/share?url=https%3A//salestribe.com/knowledge-centre/challenges/i-want-make-career-change/" target="_blank" rel="noopener noreferrer">
                                        <span className="sr-only">Google+</span>
                                        <span className="icon icon-google-plus"></span>

                                    </a>
                                </li>
                                <li className="facebook">
                                    <a href="https://facebook.com/sharer/sharer.php?u=https%3A//salestribe.com/knowledge-centre/challenges/i-want-make-career-change/" target="_blank" rel="noopener noreferrer">
                                        <span className="sr-only">Facebook</span>
                                        <span className="icon icon-facebook-f"></span>

                                    </a>
                                </li>
                                <li className="email">
                                    <a href="mailto:?subject=I%20want%20to%20make%20a%20career%20change....&amp;body=https%3A//salestribe.com/knowledge-centre/challenges/i-want-make-career-change/" target="_blank" rel="noopener noreferrer">
                                        <span className="sr-only">Email</span>
                                        <span className="icon icon-envelope"></span>

                                    </a>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </aside>
            </div>
        </div>
    </article>

    <div className="block block--base video" id="info-video">
        <div className="video__wrap">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="video__image" data-video-uri="https://www.youtube.com/embed/Qy4D_PTLL2w?autoplay=1&amp;modestbranding=0&amp;rel=0&amp;showinfo=0" data-video-target="#info-video .embed-responsive" data-video-parent="#info-video">
                            <div className="video__bgimage" style={{backgroundImage:"url(/media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-1162x655.jpg)"}}></div>
                            <div className="video__video">
                                <div className="embed-responsive embed-responsive-16by9"></div>
                            </div>
                        </div>
                        <div className="video__icon play-icon">
                            <i className="icon"></i>
                        </div>
                        <div className="video__content">
                            <div className="row">
                                <div className="video__inner col-md-offset-2 col-md-8">

                                    <h1>The new era for sales professionals.</h1>
                                    <div className="video__caption">
                                        <p className="h3">When buyers change how they buy, then sellers must also change how they sell.</p>
                                        <p>Graham Hawkins - SalesTribe Mentor</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="block block--base quote">
        <div className="container">
            <div className="row">
                <div className="quote__wrap col-sm-12">
                    <blockquote>
                        <p>For the first time in history, we sales folks get to shape how people see us before they meet us. By creating a strong, deliberate, social presence, we get to influence what people see and how they see us. We get to create the framework for how we want to be perceived by others.</p>
                        <footer>Jim Keenan</footer>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>

    {/* <div className="block block--base buttons"> */}
    {/*   <div className="container text-center"> */}
    {/*       <h3 className="buttons__title"><strong>Not sure where to start?</strong></h3> */}
    {/*       <Link to="/enquiries/" className="btn btn-default">Contact an advisor</Link> */}
    {/*   </div> */}
    {/* </div> */}
    <div className="block block--base block--ruledtop item-paging">
        <div className="container">
            <div className="row">
                <a href="/knowledge-centre/challenges/b2b-sales-getting-harder/" className="item-paging__wrap col-sm-12">
                    <div className="row">
                        <div className="item-paging__image col-sm-12 col-md-offset-1 col-md-5 col-lg-offset-2 col-lg-4">
                            <img alt="SalesTribe" src="/media/svg/landing-256x183_man-running.svg" width="420" />
                        </div>
                        <div className="item-paging__content col-sm-12 col-md-4">
                            <h3>Next challenge</h3>
                            <h2 className="item-paging__title">B2B sales is getting harder.....</h2>
                            <h3 className="item-paging__caption">63% of sales people are now failing to meet or exceed quota. Are you performing?</h3>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div className="block block--green banner">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-offset-1 col-md-10">
                    <h2 className="banner__title">Career advice, event exclusives and mentoring for the sales professional.</h2>
                    <div className="banner__buttons">
                        <button className="btn btn-default" data-toggle="modal" data-target="#signupModal">Register for Free!</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
      
    </Layout>
)

export default IndexPage 
